// Generated by Framer (21ec140)

import { addFonts, ComponentViewportProvider, cx, CycleVariantState, getFonts, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import DLook from "https://framerusercontent.com/modules/OiCf4NNLLpuN6FqtIYsk/OuCfjB574sW5UoLzNCdh/Look3D.js";
const DLookFonts = getFonts(DLook);

const cycleOrder = ["NDjoy5pLn"];

const serializationHash = "framer-BLFS2"

const variantClassNames = {NDjoy5pLn: "framer-v-1csv31e"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "NDjoy5pLn", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1csv31e", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"NDjoy5pLn"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}><ComponentViewportProvider ><motion.div className={"framer-yzsb8w-container"} layoutDependency={layoutDependency} layoutId={"isuZjg6qR-container"}><DLook draggingOptions={{transition: {damping: 100, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 600, type: false}, withDragging: true, withSnapBack: false}} height={"100%"} id={"isuZjg6qR"} layoutId={"isuZjg6qR"} perspectiveOptions={{perspectiveValue: 1900, withPerspective: true}} rotationOptions={{maxX: 90, maxY: 90, withRotationLimitX: false, withRotationLimitY: false}} sensitivity={2} style={{height: "100%", width: "100%"}} width={"100%"}/></motion.div></ComponentViewportProvider></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-BLFS2.framer-1dxvmty, .framer-BLFS2 .framer-1dxvmty { display: block; }", ".framer-BLFS2.framer-1csv31e { height: 331px; overflow: hidden; position: relative; width: 717px; }", ".framer-BLFS2 .framer-yzsb8w-container { bottom: 0px; flex: none; left: 0px; position: absolute; right: 0px; top: 0px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 331
 * @framerIntrinsicWidth 717
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framerv_HXdpup0: React.ComponentType<Props> = withCSS(Component, css, "framer-BLFS2") as typeof Component;
export default Framerv_HXdpup0;

Framerv_HXdpup0.displayName = "HeroEffects";

Framerv_HXdpup0.defaultProps = {height: 331, width: 717};

addFonts(Framerv_HXdpup0, [{explicitInter: true, fonts: []}, ...DLookFonts], {supportsExplicitInterCodegen: true})